import React from "react";
import { Controller, useForm } from "react-hook-form";
import validate from './LoginFormValidationRules';
import AuthService from "../services/auth.service";
import { useHistory } from "react-router";
import { message } from 'antd';
import { TextField } from "@material-ui/core";

const Login = () => {
    const history = useHistory();
    const { register, formState: { errors }, control, handleSubmit } = useForm();

    const onSubmit = (data) => {
        AuthService.login(data.email, data.password).then(
            (res) => {
                message.success('Login Success')
                history.push("/employees/all");
            },
            error => {
                message.error('Invalid Creadentials')
                console.log('Invalid Creadentials', 22)
            }
        );
    }
    return (

        <main id="page-content" className="d-flex nm-aic nm-vh-md-100" style={{ position: "relative" }}>
            <div className="overlay"></div>

            <div className="nm-tm-wr">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="nm-hr nm-up-rl-3">
                            <h2>Login</h2>
                        </div>
                        <div className="input-group nm-gp form-g">
                            <TextField
                                label="Email *"
                                fullWidth
                                margin="dense"
                                placeholder="Enter email" {...register("email", { required: true, minLength: 2 })} />
                            {errors.email && (errors.email.type == 'required') && <p> This Field is Required</p>}
                            {errors.email && (errors.email.type == 'minLength') && <p> Min Length is 2 char</p>}

                        </div>
                        <div className="input-group nm-gp form-g">
                            <TextField
                                label="Password *"
                                fullWidth
                                margin="dense"
                                type="password"
                                placeholder="Password" {...register("password", { required: true, minLength: 2 })} />
                            {errors.password && (errors.password.type == 'required') && <p> This Field is Required</p>}
                            {errors.password && (errors.password.type == 'minLength') && <p> Min Length is 2 char</p>}

                        </div>
                        <div className="row nm-aic nm-mb-1">
                            <div className="col-sm-12 nm-mb-1 nm-mb-sm-0">
                                <button type="submit" className="btn btn-primary nm-hvr nm-btn-2">Log In</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>






    );

}

export default Login;
