import React, { Component } from "react";
import { QuestionBox, Result } from "./index";
import UserService from "../services/user.service";
import CountDown from "./CountDown"
import DetectChatFocus from "./DetectChatFocus";
import { message } from 'antd';

class Quiz extends Component {

    state = {
        questionBank: [],
        score: 0,
        responses: [],
        start_time: null
    }

    getQuestions = (id) => {
        console.log(id, 16)
        UserService.getQuiz(id).then(
            response => {
                message.info('Don`t move to other tab or window')
                this.setState({
                    questionBank: response.data.questions,
                    start_time: response.data.start_time
                });
            },
            error => {
                message.error(error.response.data.message)
            }
        );
    }

    componentDidMount() {
        this.getQuestions(this.props.match.params.id);
    }

    computeScore = (answer, correct, question) => {
        console.log(answer, correct, question)
        const value = this.state.responses.some(element => element === answer);
        if (this.state.responses.length === 0 || !value) {
            if (answer === correct) {
                this.setState({ score: this.state.score + 1 });
            }
            this.setState({ responses: [...this.state.responses, answer] });
        }
    }

    playAgain = () => {
        this.setState({ score: 0, responses: [] });
        this.getQuestions();
    }

    logOut() {
        localStorage.removeItem("user");
        this.setState({
            currentUser: undefined,
        });
    }


    render() {
        return (
            <div className="container my-3">
                {/* <DetectChatFocus /> */}
                <a href="/login" onClick={this.logOut}>LogOut</a>
                {this.state.start_time && (
                    <CountDown start_time={this.state.start_time} />
                )}
                <div className="wrapper shadow">
                    <h2 className="m-0 bg-dark p-4 text-light">
                        Kairos Technologies
                    </h2>
                    <div className="wrapper-content p-4">
                        {this.state.questionBank.length > 0 && this.state.responses.length < 4 && this.state.questionBank.map(({ question, answers, correct, id }) => <QuestionBox question={question} answers={answers} selected={(answer) => this.computeScore(answer, correct, question)} key={id} />)}
                        {this.state.responses.length === 4 ? <Result score={this.state.score} playAgain={this.playAgain} /> : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default Quiz;