import React, { useState, useEffect } from 'react'

export default function CountDown({ start_time }) {
    var date_future = new Date(start_time);
    var date_now = new Date();
    var Totalseconds = Math.floor((date_now - date_future) / 1000);

    const [timeLeft, setTimeLeft] = useState(Totalseconds);
    const [display, setDisplay] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft((t) => {
                return t + 1
            });
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return <div>{Totalseconds}---{timeLeft}</div >;
}
