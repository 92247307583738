import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './_helpers/PrivateRoute';
import { PublicRoute } from './_helpers/PublicRoute';
import './App.css';
import Home from './components/Home';
import Login from './components/Login';
import Quiz from './components/quiz';
import notFound from './components/404-page/notFound';
import Mytest from './components/tests-list/mytest';
import { useHistory } from "react-router-dom";
import Startquiz from './components/tests-list/Startquiz';
import CreateUser from './components/users/CreateUser';
import allEmployes from './components/hrms/AllEmployes';
import CreateEditEmployee from './components/hrms/CreateEditEmployee';
import ChangePassword from './components/users/ChangePassword';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact key="/" path="/" component={allEmployes} />
          <PrivateRoute exact key="/quiz/" path="/quiz/:id" component={Startquiz} />
          <PrivateRoute exact key="/quiz/" path="/quiz" component={Quiz} />
          <PublicRoute exact key="login" path="/login" component={Login} />
          <PrivateRoute exact key='my-test' path="/my-test" component={Mytest} />
          <PrivateRoute exact key="/create-user" path="/create-user" component={CreateUser} />
          <PrivateRoute exact key="/employes" path="/employees/:company" component={allEmployes} />
          <PrivateRoute exact key="/create-emp" path="/create-emp" component={CreateEditEmployee} />
          <PrivateRoute exact key="/edit-emp" path="/edit-emp/:id" component={CreateEditEmployee} />
          <PrivateRoute exact key="/change-password" path="/change-password" component={ChangePassword} />

          <Route component={notFound} />
        </Switch>
      </BrowserRouter>

    </div>
  );
}

export default App;
