import React, { useEffect, useState, useRef } from 'react';
import userService from '../../services/user.service';
import { Container, Table, Button, Col, Navbar, Nav, NavDropdown, Form, FormControl } from 'react-bootstrap';
import authService from '../../services/auth.service';
import { Row } from 'antd';
import { Link, NavLink, useParams } from 'react-router-dom';
import empValidate from '../EmpFormValidations';
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { message } from 'antd';
import DatePicker from 'react-datepicker'
import { TextField, Select, MenuItem, InputLabel } from "@material-ui/core";
import Kairos from '../../kairos.png';
import Solunus from '../../solunus.webp';
import Orabase from '../../orabase.png';

export default function ChangePassword() {
    const history = useHistory();
    const params = useParams()

    const { register, formState: { errors }, control, watch, handleSubmit } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = (data) => {
        userService.ChangePassword(data).then((response) => {
            console.log(response, 36)
            if (response.data.error) {
                message.info(response.data.message)
            } else {
                message.success('Password changed successfully..!')
                history.push("/");
            }
        }).catch(err => {
            message.info('somthing went wrong..!')
        })
    }
    const logout = () => {
        localStorage.removeItem("user");
        history.push("/login");
    }


    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#"><img src={Kairos} /><img src={Solunus} /><img src={Orabase} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="ms-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Button variant='block' onClick={() => { logout() }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                                <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                            </svg> Logout</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div>
                <Row>
                    <Col sm={2}>
                        <div className='left-menu'>
                            <div className="d-grid gap-2">
                                <NavLink to='/employees/all' className='btn btn-primary'  >All Emps</NavLink>
                                <NavLink to='/employees/kairostech' className='btn btn-primary'>Kairos Emps</NavLink>
                                <NavLink to='/employees/solunus' className='btn btn-primary'>Solunus Emps</NavLink>
                                <NavLink to='/employees/orabasesolutions' className='btn btn-primary'>Orabase Emps</NavLink>
                                <NavLink to='/change-password' className='btn btn-primary'>Change Password</NavLink>

                            </div>
                        </div>
                    </Col>
                    <Col sm={10}>

                        <div className='body-content'>
                            <Row className='mb-3'>
                                <Col sm={8} className='text-start'>
                                    <Link className='btn btn-danger btn-sm' to='/' ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z" />
                                    </svg>Back</Link>
                                </Col>
                            </Row>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row className='g-3 border py-3 px-1 mb-3 rounded'>
                                    <Col md={3}>
                                    </Col>
                                    <Col md={6}>
                                        <Row >
                                            <Col md={12}>
                                                <Form.Group className={`mb-3 px-2 form-g`} controlId="formBasicEmail">
                                                    <TextField
                                                        label="Old Password *"
                                                        fullWidth
                                                        margin="dense"
                                                        type='password'
                                                        error={errors.Oldpassword ? true : false}
                                                        {...register('Oldpassword', {
                                                            required: "You must specify a password",
                                                        })} />
                                                    {errors.Oldpassword && <p>{errors.Oldpassword.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className={`mb-3 px-2 form-g`} controlId="formBasicEmail">
                                                    <TextField
                                                        label="New Password *"
                                                        fullWidth
                                                        margin="dense"
                                                        type='password'
                                                        error={errors.password ? true : false}
                                                        {...register('password', {
                                                            required: "You must specify a password",
                                                            minLength: {
                                                                value: 8,
                                                                message: "Password must have at least 8 characters"
                                                            }
                                                        })} />
                                                    {errors.password && <p>{errors.password.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className={`mb-3 px-2 form-g`} controlId="formBasicEmail">
                                                    <TextField
                                                        label="Confirm Password *"
                                                        fullWidth
                                                        margin="dense"
                                                        type='password'
                                                        error={errors.cPassword ? true : false}
                                                        {...register('cPassword', {
                                                            validate: value =>
                                                                value === password.current || "The passwords do not match"
                                                        })}
                                                    />
                                                    {errors.cPassword && <p>{errors.cPassword.message}</p>}
                                                </Form.Group>
                                            </Col>


                                        </Row>

                                    </Col>
                                    <Col md={3}>
                                    </Col>

                                </Row>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>


                        </div>
                    </Col>





                </Row>


            </div>
        </div>
    )
}