import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";

const DetectChatFocus = () => {
    var history = useHistory();
    const [chatFocus, setChatFocus] = useState(true);

    useEffect(() => {
        const handleActivityFalse = () => {
            localStorage.removeItem("user");
            alert('Sorry you are loged out');
            window.location.reload();
            setChatFocus(false);
        };

        const handleActivityTrue = () => {
            console.log('Welcome')
            setChatFocus(true);
        };

        window.addEventListener('focus', handleActivityTrue);
        window.addEventListener('blur', handleActivityFalse);

        return () => {
            window.removeEventListener('focus', handleActivityTrue);
            window.removeEventListener('blur', handleActivityFalse);
        };
    }, [chatFocus]);
    return (
        <div>

        </div>
    )
};

export default DetectChatFocus;