import React, { useState, useEffect } from "react";

function Question({ question, answers, no, selected }) {
    const [options, setOptions] = useState(answers);
    const [btnBG, setBtnBG] = useState("btn-outline-secondary");
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        console.log(7)
        setOptions(answers)
    })
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft((t) => {
                return t + 1
            });
            if (timeLeft > 30) {
                selected('')
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);
    return (
        <div className="card bg-light shadow-sm mb-3">
            <div className="card-body">
                <p className="fz-18 font-weight-bold">
                    {no + 1}  . {question} --- {timeLeft}
                </p>
                <ul className="m-0">
                    {options.length > 0 && options.map((text, index) =>
                        <li key={index}>
                            <button
                                className={`btn ${btnBG} btn-sm mr-2 mb-2`}
                                onClick={() => {
                                    setOptions([text]);
                                    setBtnBG("btn-secondary");
                                    selected(text);
                                }}
                                key={index}
                            >
                                {text}
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default Question;