import React, { useEffect, useState } from 'react';
import userService from '../../services/user.service';
import { Container, Table, Button, Col, Navbar, Nav, NavDropdown, Form, FormControl } from 'react-bootstrap';
import authService from '../../services/auth.service';
import { Row } from 'antd';
import { Link, NavLink, useParams } from 'react-router-dom';
import empValidate from '../EmpFormValidations';
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { message } from 'antd';
import DatePicker from 'react-datepicker'
import { TextField, Select, MenuItem, InputLabel } from "@material-ui/core";
import Kairos from '../../kairos.png';
import Solunus from '../../solunus.webp';
import Orabase from '../../orabase.png';

export default function AllEmployes() {
    const history = useHistory();
    const params = useParams()

    const [date, setDate] = useState(new Date());
    const [emps, setEmps] = useState([]);
    const [emp, setEmp] = useState({});
    const [demp, setDemp] = useState(false);


    const { register, formState: { errors }, control, handleSubmit } = useForm();
    const handleChange = (newValue) => {
        setDate(newValue);
    };
    const onSubmit = (data) => {
        if (params.id === undefined) {
            userService.CreateEmployee(data).then((response) => {
                message.success('Employee created Successfully..!')
                history.push("/employees/all");
            }).catch(err => {
                message.info('Somthing went wrong..!')
            })
        } else {
            userService.UpdateEmployee(data, params.id).then((response) => {
                message.success('Employee updated Successfully..!')
                history.push("/employees/all");
            }).catch(err => {
                message.info('Somthing went wrong..!')
            })
        }
    }
    const dataFormate = (dateVal) => {
        let dateFormate = new Date(dateVal);
        return dateFormate.toLocaleDateString("en-US");
    }
    const logout = () => {
        localStorage.removeItem("user");
        history.push("/login");
    }
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    useEffect(() => {
        if (params.id !== undefined) {
            console.log(19, params.id)
            userService.ReadEmployee(params.id).then((response) => {
                let emp = {}
                emp.emp_name = response.data.emp_name;
                emp.emp_designation = response.data.emp_designation;
                emp.emp_official_email = response.data.emp_official_email;
                emp.emp_personal_email = response.data.emp_personal_email;
                emp.emp_doj = formatDate(response.data.emp_doj);
                emp.emp_actual_dob = formatDate(response.data.emp_actual_dob);
                emp.emp_id = response.data.emp_id;
                emp.emp_job_location = response.data.emp_job_location;
                setEmp(emp)
                setDemp(true)

            }).catch((err) => {
                console.log(err, 26)
            })

        } else {
            let emp = {}
            emp.emp_name = '';
            emp.emp_designation = '';
            emp.emp_official_email = '';
            emp.emp_personal_email = '';
            emp.emp_doj = formatDate(new Date);
            emp.emp_actual_dob = formatDate(new Date);
            emp.emp_id = '';
            setEmp(emp)
            setDemp(true)
        }
    }, [])

    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#"><img src={Kairos} /><img src={Solunus} /><img src={Orabase} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="ms-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Button variant='block' onClick={() => { logout() }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                                <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                            </svg> Logout</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div>
                <Row>
                    <Col sm={2}>
                        <div className='left-menu'>
                            <div className="d-grid gap-2">
                                <NavLink to='/employees/all' className='btn btn-primary'  >All Emps</NavLink>
                                <NavLink to='/employees/kairostech' className='btn btn-primary'>Kairos Emps</NavLink>
                                <NavLink to='/employees/solunus' className='btn btn-primary'>Solunus Emps</NavLink>
                                <NavLink to='/employees/orabasesolutions' className='btn btn-primary'>Orabase Emps</NavLink>
                                <NavLink to='/change-password' className='btn btn-primary'>Change Password</NavLink>

                            </div>
                        </div>
                    </Col>
                    <Col sm={10}>

                        <div className='body-content'>
                            <Row className='mb-3'>
                                <Col sm={8} className='text-start'>
                                    <Link className='btn btn-danger btn-sm' to='/' ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z" />
                                    </svg>Back</Link>
                                </Col>
                            </Row>
                            {demp &&
                                (<Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row className='g-3 border py-3 px-1 mb-3 rounded'>
                                        <Col md={6}>
                                            <Form.Group className={`mb-3 px-2 form-g`} controlId="formBasicEmail">
                                                <TextField
                                                    label="Full Name *"
                                                    fullWidth
                                                    margin="dense"
                                                    defaultValue={emp.emp_name}
                                                    {...register('emp_name', { required: true, minLength: 2 })}
                                                    error={errors.emp_name ? true : false}
                                                />
                                                {errors.emp_name && (errors.emp_name.type == 'required') && <p> This Field is Required</p>}
                                                {errors.emp_name && (errors.emp_name.type == 'minLength') && <p> Min Length is 2 char</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3 px-2 form-g" controlId="formBasicEmail">
                                                <TextField
                                                    label="Designation"
                                                    fullWidth
                                                    defaultValue={emp.emp_designation}
                                                    margin="dense"
                                                    error={errors.emp_designation ? true : false}
                                                    {...register("emp_designation", { minLength: 2 })}
                                                />
                                                {errors.emp_designation && (errors.emp_designation.type == 'minLength') && <p> Min Length is 2 char</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3 px-2 form-g" controlId="formBasicEmail">
                                                <TextField
                                                    label="Official email *"
                                                    fullWidth
                                                    margin="dense"
                                                    defaultValue={emp.emp_official_email}
                                                    error={errors.emp_official_email ? true : false}
                                                    placeholder="Enter official email" {...register("emp_official_email", {
                                                        required: {
                                                            value: true,
                                                            message: 'Email is required'
                                                        }, pattern: {
                                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: 'Please enter a valid email',
                                                        }, minLength: {
                                                            value: 4,
                                                            message: 'Minimum length is 4'
                                                        }
                                                    })} />
                                                {errors.emp_official_email && (errors.emp_official_email.type == 'required') && <p> {errors.emp_official_email?.message}</p>}
                                                {errors.emp_official_email && (errors.emp_official_email.type == 'minLength') && <p> {errors.emp_official_email?.message}</p>}
                                                {errors.emp_official_email && (errors.emp_official_email.type == 'pattern') && <p> {errors.emp_official_email?.message}</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3 px-2 form-g" controlId="formBasicEmail">
                                                <TextField name="emp_personal_email"
                                                    label="Personal email"
                                                    fullWidth
                                                    margin="dense"
                                                    defaultValue={emp.emp_personal_email}
                                                    error={errors.emp_personal_email ? true : false}
                                                    placeholder="Enter personal email" {...register("emp_personal_email", {
                                                        pattern: {
                                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: 'Please enter a valid email',
                                                        }, minLength: {
                                                            value: 4,
                                                            message: 'Minimum length is 4'
                                                        }
                                                    })} />
                                                {errors.emp_personal_email && (errors.emp_personal_email.type == 'required') && <p> {errors.emp_personal_email?.message}</p>}
                                                {errors.emp_personal_email && (errors.emp_personal_email.type == 'minLength') && <p> {errors.emp_personal_email?.message}</p>}
                                                {errors.emp_personal_email && (errors.emp_personal_email.type == 'pattern') && <p> {errors.emp_personal_email?.message}</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3 px-2 form-g" controlId="formBasicPassword">
                                                <TextField
                                                    label="Employee ID"
                                                    fullWidth
                                                    margin="dense"
                                                    defaultValue={emp.emp_id}
                                                    error={errors.emp_id ? true : false}
                                                    placeholder="Employee ID" {...register("emp_id", { minLength: 2 })} />
                                                {errors.emp_id && (errors.emp_id.type == 'minLength') && <p> Min Length is 2 char</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3 px-2 form-g" controlId="formBasicEmail">
                                                <TextField label="Actual DOB *"
                                                    fullWidth
                                                    margin="dense"
                                                    error={errors.emp_actual_dob ? true : false}
                                                    defaultValue={emp.emp_actual_dob}
                                                    type='date' placeholder="Date" {...register("emp_actual_dob", { required: true })} />
                                                {errors.emp_actual_dob && (errors.emp_actual_dob.type == 'required') && <p> This Field is Required</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3 px-2 form-g" controlId="formBasicEmail">
                                                <TextField
                                                    fullWidth
                                                    label="Joing Date *"
                                                    margin="dense"
                                                    defaultValue={emp.emp_doj}
                                                    error={errors.emp_doj ? true : false}
                                                    type="date" placeholder="Date"  {...register("emp_doj", { required: true })} />
                                                {errors.emp_doj && (errors.emp_doj.type == 'required') && <p> This Field is Required</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3 px-2 form-g select-cus text-start" controlId="formBasicEmail">
                                                <InputLabel >Select Company</InputLabel>
                                                <Select
                                                    fullWidth
                                                    margin="dense"
                                                    defaultValue={emp.emp_job_location}
                                                    placeholder='Selcect Company'
                                                    label="Select Company"
                                                    {...register("emp_job_location", { required: true })}>
                                                    <MenuItem value='Solunus Hyderabad'>Solunus Technolgies</MenuItem>
                                                    <MenuItem value='Kairos Hyderabad'>Kairos Hyderabad</MenuItem>
                                                    <MenuItem value='Kairos Vizag'>Kairos Vizag</MenuItem>
                                                    <MenuItem value='Kairos USA'>Kairos USA</MenuItem>
                                                    <MenuItem value='Orabase'>Orabase Solutions</MenuItem>
                                                </Select>
                                                {errors.emp_job_location && (errors.emp_job_location.type == 'required') && <p> This Field is Required</p>}
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>)
                            }

                        </div>
                    </Col>





                </Row>


            </div>
        </div>
    )
}