import React, { useEffect, useState } from "react";
import userService from "../../services/user.service";
import {
  Container,
  Table,
  Button,
  Col,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
} from "react-bootstrap";
import authService from "../../services/auth.service";
import { Row } from "antd";
import { NavLink, Link, useParams } from "react-router-dom";
import { message } from "antd";
import { useHistory } from "react-router";
import Kairos from "../../kairos.png";
import Solunus from "../../solunus.webp";
import Orabase from "../../orabase.png";
import { BsFillTrashFill } from "react-bootstrap-icons";
import { Select, MenuItem, InputLabel } from "@material-ui/core";

export default function AllEmployes() {
  const params = useParams();
  const history = useHistory();

  if (params.company === undefined || params.company === "all") {
    var compnay = "";
  } else {
    var compnay = params.company;
  }
  const [emps, setEmps] = useState([]);
  const [ser, setSer] = useState(compnay);
  const [serbyname, setSerbyname] = useState("");

  const dataFormate = (dateVal) => {
    let options = { year: "numeric", month: "short", day: "numeric" };
    let dateFormate = new Date(dateVal);
    return dateFormate.toLocaleDateString("en-US", options);
  };
  const handleDelete = (id, emp_name) => {
    userService
      .DeleteEmployee(id)
      .then((response) => {
        let newList = emps.filter((item) => item._id !== id);
        setEmps(newList);
        message.success("Employee deleted Successfully..!");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const logout = () => {
    localStorage.removeItem("user");
    history.push("/login");
  };
  const getData = (body = "") => {
    var dt = { date: "" };
    if (body) {
      var dt = { date: "1990-" + body + "-21" };
    }
    userService
      .allEmployees(dt)
      .then((res) => {
        setEmps(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logout();
        }
        console.log(56, error.response.status);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setSer(compnay);
  });

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              src={
                "https://kairostech.com/wp-content/themes/Kairos/assets/images/kairos-logo-dark.png"
              }
            />
            <img src={Solunus} />
            <img src={Orabase} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Button
                variant="block"
                onClick={() => {
                  logout();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-box-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                  />
                </svg>{" "}
                Logout
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Row>
          <Col sm={2}>
            <div className="left-menu">
              <div className="d-grid gap-2">
                <NavLink to="/employees/all" className="btn btn-primary">
                  All Emps
                </NavLink>
                <NavLink to="/employees/kairostech" className="btn btn-primary">
                  Kairos Emps
                </NavLink>
                <NavLink to="/employees/solunus" className="btn btn-primary">
                  Solunus Emps
                </NavLink>
                <NavLink
                  to="/employees/orabasesolutions"
                  className="btn btn-primary"
                >
                  Orabase Emps
                </NavLink>
                <NavLink to="/change-password" className="btn btn-primary">
                  Change Password
                </NavLink>
              </div>
            </div>
          </Col>
          <Col sm={10}>
            <div className="body-content">
              <Row className="mb-3 align-items-center">
                <Col sm={4} className="text-start">
                  <Link className="btn btn-success btn-sm" to="/create-emp">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                      <path
                        fillRule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>{" "}
                    Add Employee
                  </Link>
                </Col>
                <Col sm={4}>
                  <Form.Group
                    className="mb-3 px-2 form-g select-cus text-start"
                    controlId="formBasicEmail"
                  >
                    <InputLabel id="demo-multiple-name-label">
                      Select Month DOB
                    </InputLabel>
                    <Select
                      fullWidth
                      label="Select Month"
                      onChange={(e) => getData(e.target.value)}
                    >
                      <MenuItem value="" disable>
                        Select
                      </MenuItem>
                      <MenuItem value="01">Jan</MenuItem>
                      <MenuItem value="02">Feb</MenuItem>
                      <MenuItem value="03">Mar</MenuItem>
                      <MenuItem value="04">Apr</MenuItem>
                      <MenuItem value="05">May</MenuItem>
                      <MenuItem value="06">Jun</MenuItem>
                      <MenuItem value="07">Jul</MenuItem>
                      <MenuItem value="08">Aug</MenuItem>
                      <MenuItem value="09">Sep</MenuItem>
                      <MenuItem value="10">Oct</MenuItem>
                      <MenuItem value="11">Nov</MenuItem>
                      <MenuItem value="12">Dec</MenuItem>
                    </Select>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form className="d-flex">
                    <FormControl
                      type="search"
                      placeholder="Search By Name"
                      onChange={(e) => setSerbyname(e.target.value)}
                      aria-label="Search"
                    />
                  </Form>
                </Col>
              </Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>DOJ</th>
                    <th>DOB</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {emps
                    .filter(
                      (emp) =>
                        emp.emp_name.toLowerCase().includes(serbyname) &&
                        emp.emp_official_email.includes(ser)
                    )
                    .map((emp, i) => (
                      <tr key={emp._id}>
                        <td>{i + 1}</td>
                        <td>{emp.emp_name}</td>
                        <td>{emp.emp_official_email}</td>
                        <td>{dataFormate(emp.emp_doj)}</td>
                        <td>{dataFormate(emp.emp_actual_dob)}</td>
                        <td>
                          <Link
                            className="btn btn-primary btn-sm mx-1"
                            to={`/edit-emp/${emp._id}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </Link>
                          <Button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              {
                                if (
                                  window.confirm(
                                    "Delete the employee name " +
                                      emp.emp_name +
                                      "?"
                                  )
                                ) {
                                  handleDelete(emp._id, emp.emp_name);
                                }
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                            </svg>
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
