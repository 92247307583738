import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "http://18.221.217.67:8002/api/";
const hrms_url = "//175.101.4.30:8700/";

class UserService {
  getQuiz(id = 12) {
    console.log(id);
    return axios.get(API_URL + `exams` + id + `/`, { headers: authHeader() });
  }
  getExams() {
    return axios.get(API_URL + "exams", { headers: authHeader() });
  }
  categories() {
    return axios.get(API_URL + "category", { headers: authHeader() });
  }
  CreateUser(data) {
    return axios.post(API_URL + "register", data, { headers: authHeader() });
  }
  joinTest(data) {
    return axios.post(API_URL + "test", { headers: authHeader() });
  }
  allEmployees(data) {
    console.log(data);
    return axios.post(hrms_url + "empdata/all-employees", data, {
      headers: authHeader(),
    });
  }
  CreateEmployee(data) {
    return axios.post(hrms_url + "empdata/create-emp", data, {
      headers: authHeader(),
    });
  }
  ReadEmployee(id) {
    return axios.get(hrms_url + "empdata/read-emp/" + id, {
      headers: authHeader(),
    });
  }
  UpdateEmployee(data, id) {
    return axios.put(hrms_url + "empdata/update-empdata/" + id, data, {
      headers: authHeader(),
    });
  }
  DeleteEmployee(id) {
    return axios.delete(hrms_url + "empdata/delete-emp/" + id, {
      headers: authHeader(),
    });
  }
  ChangePassword(data) {
    return axios.post(hrms_url + "users/change-password", data, {
      headers: authHeader(),
    });
  }
}

export default new UserService();
