import React, { useState, useEffect } from 'react';
import Question from './Question';
import userService from '../../services/user.service';
import Result from '../Result';
import DetectChatFocus from '../DetectChatFocus';

export default function Startquiz(props) {
    const [score, setScore] = useState(0);
    const [data, setData] = useState([]);
    const [question, setQuestion] = useState([]);
    const [q, setQ] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [responses, setResponses] = useState([]);
    useEffect(() => {
        userService.getQuiz(props.match.params.id).then((response => {
            console.log(response.data)
            setQuestion(response.data.questions)
        })
        ).catch(error =>
            console.log(error)
        )

    }, []);
    useEffect(() => {
        console.log(q, 26)
    }, [q]);
    const computeScore = (answer, correct, question) => {
        const value = responses.some(element => element === answer);
        if (responses.length === 0 || !value) {
            if (answer === correct) {
                setScore(score + 1)
            }
            // setResponses({ responses: [...responses, answer] });
            console.log(q)
            setQ(q + 1)

        }
    }
    const playAgain = () => {
        console.log('done')
    }
    return (
        <div className="container my-3">
            <DetectChatFocus />
            {/* <a href="/login" onClick={this.logOut}>LogOut</a> */}
            <div className="wrapper shadow">
                <h2 className="m-0 bg-dark p-4 text-light">
                    Kairos Technologies
                </h2>
                <div className="wrapper-content p-4">
                    {(question.length > 0) && (q < 4) && (
                        <Question question={question[q].question} answers={question[q].answers} no={q} selected={(answer) => computeScore(answer, question[q].correct, question[q].question)} key={q} />
                    )}
                    {q === 4 ? <Result score={score} playAgain={playAgain} /> : null}
                </div>
            </div>
        </div>
    )
}
