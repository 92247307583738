import React, { useState, useEffect } from "react";
import axios from "axios";

function Home() {
  const [count, setCount] = useState(0);
  const [emp, setEmp] = useState([]);
  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count === 0) return;
    setCount(count - 1);
  };
  const apiData = () => {
    axios
      .get("http://175.101.4.30:8700/empdata/birthday-this-month")
      .then((response) => {
        setEmp(response.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    console.log("clicked");
  }, [emp]);

  return (
    <div>
      <h1>Hi</h1>
      <button onClick={apiData}>Get API Data</button>
      <ul>
        {emp.map((e, i) => {
          return <li key={i}>{e.emp_name}</li>;
        })}
      </ul>
      <button onClick={increment}>Increment </button>
      {count}
      <button onClick={decrement}>Decrement </button>
    </div>
  );
}

export default Home;
