import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import UserService from "../../services/user.service";

export default function CreateUser() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [data, setData] = useState([]);
    useEffect(() => {
        UserService.categories().then(
            response => {
                setData(response.data)
            },
            error => {
                console.log(22, error)
            }
        );
    }, [])
    const onSubmit = (data) => {
        alert(JSON.stringify(data));
        UserService.CreateUser(data).then((res) => {
            console.log(res, 21)
        }).catch(error => {
            console.log(error.response, 23)
        })
    };
    const options = () => {
        console.log(errors, 32)
        return data.map((item) => {
            return (<option key={item.id} value={item.id}>{item.category_name}</option>)
        })

    }
    //console.log(watch("username")); 

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>First Name</label>
            <input
                {...register("username", {
                    required: true,
                    minLength: 3,
                    maxLength: 20
                })}
            />
            {errors?.username?.type === "required" && <p>Name field is required</p>}
            {errors?.username?.type === "minLength" && (
                <p>Name Min 3 characters</p>
            )}
            {errors?.username?.type === "maxLength" && (
                <p>Name cannot exceed 20 characters</p>
            )}
            <label>Email</label>
            <input {...register("email", { required: true, pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i })} />
            {errors?.email?.type === "required" && <p>Email field is required</p>}
            {errors?.email?.type === "pattern" && (
                <p>Enter valid email</p>
            )}
            <label>Category</label>
            <select  {...register("category_id", {
                required: true
            })} >
                <option value=''>Select Category</option>
                {options()}
            </select>
            {errors.category_id && (
                <p>Please select category</p>
            )}
            <input type="submit" />
        </form>
    );
}

