import axios from "axios";

const API_URL = "http://18.221.217.67:8001/api/";
const HRMS_URL = "//175.101.4.30:8700/";

class AuthService {
  login(email, password) {
    return axios
      .post(HRMS_URL + "users/signIn", {
        email,
        password,
      })
      .then((response) => {
        console.log(13, response.data);
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  check_email(email) {
    return axios.post(API_URL + "check_email", { email }).then(
      (response) => {
        if (response.data.error === true) {
          console.log(107, response.data);
          return false;
        } else {
          return true;
        }
      },
      (error) => {
        console.log(114, error.response);
      }
    );
  }
  check_phoneno(phoneno) {
    return axios.post(API_URL + "check_phoneno/", { phoneno }).then(
      (response) => {
        if (response.data.error === true) {
          console.log(107, response.data);
          return false;
        } else {
          return true;
        }
      },
      (error) => {
        console.log(114, error.response);
      }
    );
  }
  logout() {
    localStorage.removeItem("user");
  }
  register(name, email, phoneno, password, deviceid = "nodeviceid") {
    console.log(name, 26);
    return axios.post(API_URL + "users/", {
      name,
      email,
      phoneno,
      password,
      deviceid,
    });
  }

  registerOTP(name, email, phoneno, password, otp, deviceid = "") {
    console.log(36, otp);
    return axios
      .post(API_URL + "verifyotp/", {
        name,
        email,
        phoneno,
        password,
        otp,
      })
      .then((response) => {
        console.log(13, response.data);
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  ForgotPassordGetOTP(username) {
    return axios.post(API_URL + "forgot_password/", { username });
  }
  ForgotPassordOTPVerification(username, otp) {
    return axios
      .post(API_URL + "forgot_password_verify/", { username, otp })
      .then((response) => {
        console.log(13, response.data);
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
