import React, { useEffect, useState } from 'react'
import { useFetchPost, useFetchGet } from "../../services/useFetch";
import UserService from "../../services/user.service";
import { Link } from "react-router-dom";

export default function Mytest() {
    const [data, setData] = useState([]);
    useEffect(() => {
        UserService.getExams().then(
            response => {
                console.log(response.data, 9)
                setData(response.data)
                console.log(data, 133)
            },
            error => {
                console.log(22, error)
            }
        );
    }, [])

    return (
        <div>
            <h1>My Test list</h1>
            <table>
                <thead>
                    <tr><td>S. No</td>
                        <td>Name</td>
                        <td>Actoin</td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((exam, i) =>
                        <tr key={exam.id}>
                            <td>{i + 1}</td>
                            <td>{exam.title}</td>
                            <td><Link to={`/quiz/${exam.id}`}>Join</Link></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
